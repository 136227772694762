import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")
var showdown = require("showdown")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    const wrapperClasses = classNames(
      "body-sections section joshua-tree-content proc-text-section",
      {
        "color-back": this.props.colorBack
      }
    )

    return (
        <section className={wrapperClasses} style={{paddingTop: this.props.paddingTop,paddingBottom: this.props.paddingBottom}}>
          <div className="columns">
            <div className={`column is-${this.props.sideColumnIs}`}></div>

            <div className="column">
              <MarkdownViewer markdown={this.props.textSection} />
              {this.props.videoSection &&
                <VideoSection data={this.props.videoSection} />
              }
            </div>
            <div className={`column is-${this.props.sideColumnIs}`}></div>
          </div>
        </section>
    )
  }
}

function VideoSection({ data }) {
  if (data.hasImage) {
    return (
      <div>
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${data.youtube}`}
          controls={true}
          playing={true}
          overVideo={true}
          hasWatchVideo={true}
        >
          <ImageMeta
            cloudName="nuvolum"
            publicId={data.imageId}
            width="auto"
            responsive
            responsiveUseBreakpoints="true"
          ></ImageMeta>
        </TheaterVideo>

        {data.content && 
          <MarkdownViewer markdown={data.content} />
        }
      </div>
    )
  } else {
    return (
      <div>
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${data.youtube}`}
          controls={true}
          playing={true}
          overVideo={false}
          hasWatchVideo={true}
        >
        </TheaterVideo>
        {data.content && 
          <MarkdownViewer markdown={data.content} />
        }
      </div>
    )
  }
  
}

MarkdownSection.propTypes = {
    textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number,
}

export default MarkdownSection
